import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp } from "@fortawesome/free-solid-svg-icons";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Detecta si el usuario se ha desplazado hacia abajo lo suficiente para mostrar el botón.
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Agrega un evento de escucha al desplazamiento de la ventana para detectar si se debe mostrar el botón.
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    // Limpia el evento de escucha al desmontar el componente.
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Desplaza la ventana al principio de la página cuando se hace clic en el botón.
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  // Renderiza el botón solo si es visible.
  return (
    <>
      {isVisible && (
        <button className="scroll-to-top-button d-none d-md-flex" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 35, background: "white", borderRadius: "50%", border: "2px solid white"}} className="" />
        </button>
      )}
    </>
  );
}

