import { Ayuda } from "../components/public/Ayuda";
import { Contacto } from "../components/public/Contacto";
import { Formulario } from "../components/public/Formulario";

export const ContactoScreen = () => {
  return (
    <>
      <Contacto />
      <Ayuda />
      <Formulario />
    </>
  );
};
