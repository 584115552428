export const LinksDeInteres = () => {
  return (
    <>
      <div className="container pt-4">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="titulo-link-de-interes text-shadow" >
              Información de interés
            </h2>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mt-5 mb-5">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div
              class="card mb-4 box-shadow"
              id="cardNoticias"
              
            >
              <img
                class="card-img-top"
                src="https://servicioscf.afip.gob.ar/publico/sitio/contenido/common/image.aspx?i=2175|promindustrialcabecera.jpg"
                alt="Noticias RyM"
              />
              <div class="card-body">
                <a
                  href="https://servicioscf.afip.gob.ar/publico/sitio/contenido/novedad/ver.aspx?id=2604"
                  target="_blank"
                  className="text-decoration-none"
                >
                  <h5 class="card-title text-dark ">
                  Beneficios para el personal registrado
                  </h5>
                </a>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div
              class="card mb-4 box-shadow"
              id="cardNoticias"
              
            >
              <img
                class="card-img-top"
                src="https://servicioscf.afip.gob.ar/publico/sitio/contenido/common/image.aspx?i=2123|banner-vencimientos-SEPT-cab.jpg"
                alt="Noticias RyM"
              />
              <div class="card-body">
                <a
                  href="https://servicioscf.afip.gob.ar/publico/sitio/contenido/novedad/ver.aspx?id=2521"
                  target="_blank"
                  className="text-decoration-none"
                >
                  <h5 class="card-title text-dark">
                  Vencimientos de Septiembre
                  </h5>
                </a>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div
              class="card mb-4 box-shadow"
              id="cardNoticias"
              
            >
              <img
                class="card-img-top"
                src="https://servicioscf.afip.gob.ar/publico/sitio/contenido/common/image.aspx?i=2177|banner_web_reintegro-nov.jpg"
                alt="Noticias RyM"
              />
              <div class="card-body">
                <a
                  href="https://servicioscf.afip.gob.ar/publico/sitio/contenido/novedad/ver.aspx?id=2602"
                  target="_blank"
                  className="text-decoration-none text-dark"
                >
                  <h5 class="card-title">Reintegro por compras con tarjeta de débito</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
