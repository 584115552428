import {
  faCar,
  faComments,
  faFileZipper,
  faGraduationCap,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsCheckCircleFill } from "react-icons/bs";
import "../../styles/styles.css";

export const Cards = () => {
  return (
    <div className="bg-acordeon">
      <div className=" container pt-4 pb-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <h5
              className="nuestrosservicios text-start text-shadow"
              id="tituloDesplegables" 
              
            >
              ASESORÍA
            </h5>
            <h1
              className="realizamos text-start text-shadow"
              id="tituloDesplegables"
              
            >
              Contable
            </h1>
          </div>
          <div className="container">
            <div className="accordion accordion-flush">
              <div className="accordion-item mb-3">
                <h2 className="accordion-header" id="#flush-collapseOne">
                  <button
                    className="a1 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <FontAwesomeIcon
                      icon={faComments}
                      style={{ height: 25, paddingRight: 10 }}
                    />
                    MONOTRIBUTO
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent=".a1"
                >
                  <div className="accordion-body">
                    <ol className="list-unstyled">
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta en el Monotributo
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta Puntos de Ventas
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta Ingresos Brutos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Gestión de trámites AFIP y Beneficios
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Declaraciones CBU
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Formulario 960
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Declaración jurada mensual y anual de Ingresos Brutos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Análisis mensual y periodo fiscal
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Recategorización
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Baja/Modificación de Categoría
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Plan de pago
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Asesoramiento completo de su actividad
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3" >
                <h2 className="accordion-header">
                  <button
                    className="a2 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ height: 25, paddingRight: 10 }}
                    />
                    TRABAJADORES DE CASAS PARTICULARES
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent=".a2"
                >
                 <div className="accordion-body">
                    <ol className="list-unstyled">
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Como empleador podés deducir del impuesto a las
                        ganancias lo que pagues como sueldo y contribuciones de
                        seguridad social (obra social, jubilación, etc.).
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Como trabajador podés acceder a cobertura de obra
                        social, jubilación, vacaciones, aguinaldo y ART.
                      </li>
     
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3" >
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="a3 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    <FontAwesomeIcon
                      icon={faFileZipper}
                      style={{ height: 25, paddingRight: 10 }}
                    />
                    RESPONSABLE INSCRIPTO
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent=".a3"
                >
                  <div className="accordion-body">
                    <ol className="list-unstyled">
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta Responsable Inscripto
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta de Impuestos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Liquidación y Presentación IVA mensual
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Liquidación y Presentación Ingresos Brutos mensual
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Declaración jurada Anual Ingresos Brutos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Anticipo Impuesto a las Ganancias
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Declaración jurada mensual y anual de Ingresos Brutos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Análisis mensual y periodo fiscal
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Control de Facturación
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Avisos de Vencimientos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Asesoramiento completo de su actividad
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item mb-3" >
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="a4 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    <FontAwesomeIcon
                      icon={faCar}
                      style={{ height: 25, paddingRight: 10 }}
                    />
                    PYME
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent=".a4"
                >
                  <div className="accordion-body">
                    <ol className="list-unstyled">
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Liquidación de Impuestos Mensuales
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Presentación de Impuestos Mensuales
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Liquidación de Impuestos Anuales
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Aviso de Vencimientos
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Sueldos: 5 empleados
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Asesoramiento
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="accordion-item" >
                <h2 className="accordion-header" id="flush-headingFive">
                  <button
                    className="a5 accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    <FontAwesomeIcon
                      icon={faGraduationCap}
                      style={{ height: 25, paddingRight: 10 }}
                    />
                    SUELDOS
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent=".a5"
                >
                  <div className="accordion-body">
                    <ol className="list-unstyled">
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta de Empleador
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Alta de Empleados
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Baja de Empleados
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        F. 931
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        F. 885
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Liquidación de Sueldos y Jornales
                      </li>
                      <li>
                        <span className="text-success me-2">
                          <BsCheckCircleFill />
                        </span>{" "}
                        Libro Sueldo Digital
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
