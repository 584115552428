import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '500px',
  height: '350px',
};

const center = {
  lat: -27.468153,
  lng: -58.846175,
};

function Map() {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBtFs9b3o3kg5bVDwVbR2zHu3hD1dRbmig"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
      >
        <Marker
          position={center}
          title=""
          label="R&M"
        />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;



