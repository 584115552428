import { useState } from "react";
import emailjs from "@emailjs/browser";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export const Formulario = () => {
  const [setFormularioEnviado] = useState(false);
  const [enviandoFormulario, setEnviandoFormulario] = useState(false);

  const enviarEmail = (event) => {
    event.preventDefault();

    if (validarFormulario() && !enviandoFormulario) {
      setEnviandoFormulario(true);
      emailjs
        .sendForm(
          "service_i7r9uvb",
          "template_c8yzmtt",
          event.target,
          "2p0oAOff099358Gyq"
        )
        .then((response) => {
          setEnviandoFormulario(false);

          if (response.status === 200) {
            Swal.fire(
              "Su mensaje ha sido enviado",
              "Nos contactaremos a la brevedad",
              "success"
            ).then(
              () => setFormularioEnviado(true),
              setTimeout(() => {
                window.location.reload();
              }, 3000)
            );
          } else {
            Swal.fire(
              "Su consulta no pudo ser enviada",
              "Intente en unos minutos",
              "error"
            );
          }
        })
        .catch(() => setEnviandoFormulario(false));

      // Actualizar estado para indicar que el formulario ya fue enviado
      setFormularioEnviado(true);
    }
  };

  const validarFormulario = () => {
    const nombre = document.getElementById("emailForm").value.trim();
    const email = document.getElementById("email").value.trim();
    const asunto = document.getElementById("asunto").value.trim();
    const mensaje = document.getElementById("textAreaForm").value.trim();

    if (!nombre || !email || !asunto || !mensaje) {
      Swal.fire(
        "Faltan campos por completar",
        "Por favor complete todos los campos antes de enviar el formulario",
        "warning",
        "OK"
      );
      return false;
    }

    // Validar que el campo de email tenga un formato válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire(
        "Formato de correo electrónico inválido",
        "Por favor ingrese un correo electrónico válido",
        "warning",
        "OK"
      );
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="bg-form">
        <div className="container pt-4 pb-4">
          <div className="row justify-content-center">
            <div className="col-12">
              <h5 className="nuestrosservicios text-shadow" >
                DEJANOS TU CONSULTA
              </h5>
              <h1 className="titulo-contacto text-shadow" >Contactanos</h1>
            </div>
          </div>
        </div>
        <div className="container contact-form" >
          <form onSubmit={enviarEmail}>
            <div className="row" >
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    id="emailForm"
                    name="user_name"
                    className="form-control mb-3"
                    placeholder="Nombre y Apellido"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="user_email"
                    className="form-control mb-3"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="asunto"
                    name="user_asunto"
                    className="form-control mb-3"
                    placeholder="Asunto"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <textarea
                    type="text"
                    name="user_mensaje"
                    id="textAreaForm"
                    className="form-control"
                    placeholder="Dejanos su consulta . . ."
                  ></textarea>
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-end">
                <button className="enviarForm btn btn-outline">
                  ENVIAR MENSAJE
                  <span>
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      style={{ height: 17, paddingLeft: "8px" }}
                      className="me-1"
                    />
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
