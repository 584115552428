import { Link } from "react-router-dom";

export const CarruselCard = () => {
  return (
    <>
      <div className="container">
        <div className="col-xs-12 text-center mt-5">
          <h5
            className="nuestrosservicios text-start text-shadow"
            id="tituloCard"
          >
            NUESTROS SERVICIOS
          </h5>
          <h1 className="realizamos text-start text-shadow" id="tituloCard">
            Realizamos
          </h1>
        </div>
      </div>

      <div className="container">
        <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        MONOTRIBUTO
                      </h5>
                      <h6 class="card-subtitle mb-2 text-muted text-center">
                        Ponemos a punto tu emprendimiento, te damos
                        asesoramiento completo, controlamos tu facturación y
                        categorización.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        RESPONSABLE INSCRIPTO
                      </h5>
                      <h6 class="card-subtitle mb-2 text-muted text-center">
                        Nos encargamos de todos los impuestos para que vos solo
                        te enfoques en hacer crecer tu negocio.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        LIQUIDACIÓN IIBB CONVENIO MULTILATERAL{" "}
                      </h5>
                      <h6 class="card-subtitle text-muted text-center">
                        Mantenemos tus ingresos brutos en orden para evitar que
                        te retengan de más.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        LIQUIDACIÓN IVA{" "}
                      </h5>
                      <h6 class="card-subtitle mb-2 text-muted text-center">
                        Confeccionamos tus liquidaciones y realizamos la
                        presentación correspondiente todos los meses del Libro
                        Digital de IVA.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="carousel-item">
              <div class="row  ">
                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        LIQUIDACIÓN GANANCIA Y BIENES PERSONALES{" "}
                      </h5>
                      <h6 class="card-subtitle  text-muted text-center">
                        Analizamos y Confeccionamos la Liquidación Anual de
                        estos Impuestos para luego presentar las Declaraciones
                        Juradas correspondientes.
                      </h6>
                      <div className="mt-4 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        LIQUIDACIÓN DE SUELDOS{" "}
                      </h5>
                      <h6 class="card-subtitle text-muted text-center">
                        Te brindamos un servicio integral de liquidación de
                        haberes para garantizar el cumplimiento de las
                        obligaciones relacionadas con tu nómina.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        FACTURACIÓN{" "}
                      </h5>
                      <h6 class="card-subtitle  text-muted text-center">
                        Realizamos tus facturas para que no pierdas tiempo y
                        solo te ocupes de tu negocio.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-outline-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col mb-5 mt-3 d-flex justify-content-center">
                  <div class="card" id="cardCarrusel" data-aos-duration="1000">
                    <div
                      class="card-body d-flex flex-column justify-content-between"
                      id="bg-card-carrusel"
                    >
                      <h5 class="card-title text-center" id="tituloCard">
                        OTROS SERVICIOS{" "}
                      </h5>
                      <h6 class="card-subtitle  text-muted text-center">
                        Con una fluida comunicación te brindamos un completo
                        asesoramiento en certificaciones y otros Trámites
                        Especiales.
                      </h6>
                      <div className="mt-5 text-center">
                        <Link
                          to="/contacto"
                          className="btn btn-light"
                          // id="contactanosNav"
                          id="botonCardCarrusel"
                        >
                          CONTACTANOS
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};
