import { Ayuda } from "../components/public/Ayuda";
import { Cards } from "../components/public/Cards";
import { CarruselCard } from "../components/public/CarruselCard";
import { Contacto } from "../components/public/Contacto";
import { Formulario } from "../components/public/Formulario";
import { LinksDeInteres } from "../components/public/LinksDeInteres";
import { Principal } from "../components/public/Principal";

export const HomeScreen = () => {
  return (
    <>
      <Principal />
      <CarruselCard />
      <Cards />
      <Ayuda />
      <Contacto />
      <LinksDeInteres />
      <Formulario />
    </>
  );
};
