import sobrenosotros from "../../assets/rym.webp";

export const Nosotros = () => {
  const parrafos = {
    p1: "Tenemos como premisas brindar un excelente servicio profesional y ser reconocido como un estudio de mercado.",
    p2: "Nos parece muy importante, más con mi formación académica y de investigación, la constante capacitación en todas las áreas de la profesión. Y es un objetivo a mediano plazo de nuestro estudio, poder estar brindando capacitación profesional a otros colegas y a personas de otras profesiones o del ámbito empresario o gubernamental. Es un objetivo de largo plazo del estudio el poder brindar soluciones integrales de distintas áreas a las organizaciones de distintas envergaduras y de distintos ámbitos.",
    p3: "Creemos que esto es posible ya que el trabajo interdisciplinario y nuestra formación en el área de las organizaciones permite coordinar o ser partícipes de estas tareas.",
  };

  return (
    <>
      <div className="container my-4">
        <div className="row align-items-center">
          <div className="col-12">
            <h5
              className="nuestrosservicios text-start text-shadow"
              id="nosotros"
            >
              ¿QUIENES SOMOS?
            </h5>
            <h1 className="realizamos text-start text-shadow" id="nosotros">
              Nosotras
            </h1>
          </div>
          <div className="col-md-6 order-md-2">
            <img
              src={sobrenosotros}
              className="img-fluid"
              id="imagensobrenosotros"
              alt="Imagen a la derecha"
            />
          </div>
          <div className="texto-sobrenosotros col-md-6 order-md-1 ">
            <p className="mb-3">{parrafos.p1}</p>
            <p className="mb-3">{parrafos.p2}</p>
            <p className="mb-3">{parrafos.p3}</p>
          </div>
        </div>
      </div>
    </>
  );
};
