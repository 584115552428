import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Navbar } from './components/public/Navbar';
import { Footer } from './components/public/Footer';
import { Whatsapp } from './components/public/Whatsapp';
import { ScrollToTopButton } from './components/public/ScrollToTopButton';
import { ContactoScreen } from './screens/ContactoScreen';
import { NosotrosScreen } from './screens/NosotrosScreen';
import { HomeScreen } from './screens/HomeScreen';
import { ServiciosScreen } from './screens/ServiciosScreen';


function App() {
  return (
    <div id="infoDeInteres">
      <Navbar />
      <Routes >
        <Route exact path={"/"} element={<HomeScreen />} />
        <Route exact path={"/nosotros"} element={<NosotrosScreen />} />
        <Route exact path={"/contacto"} element={<ContactoScreen />} />
        <Route exact path={"/servicios"} element={<ServiciosScreen />} />
        <Route exact path="*" element={<HomeScreen />} />

      </Routes>
      <Footer /> 
      <Whatsapp />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
