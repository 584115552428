import "../../styles/styles.css";
import whatsapp from "../../assets/wp1.webp";

export const Whatsapp = () => {
  return (
    <>
      <div className="whatsapp-container">
        <a
          href="https://wa.me/+543794223834?text=Hola%20buenos%20días!%20Me%20gustaría%20realizar%20una%20consulta%20contable.%20"
          target="_blank"
          rel="noreferrer"
        >
          <img className="boton" src={whatsapp} alt="Icono WhatsApp" />
        </a>
      </div>
    </>
  );
};
