import "../../styles/styles.css";
import logo from "../../assets/isologomarron.png";

export const Ayuda = () => {
  return (
    <div className="mb-4 mt-4">
      <div className="container text-center pb-3 pt-1">
        <img
          src={logo}
          alt="logo-impuestos"
          className=""
          style={{ height: "100px", width: "200px" }}
          
        />
        <div className="row justify-content-center align-items-center">
          <div className="col-md-12">
            <h1 className="mt-3"  style={{ color: "#824b36" }}>
              ¿Necesitas ayuda?
            </h1>
            <h3 className="" style={{ color: "#824b36" }}>
              Llamanos al <strong>+54 3772-223834</strong>, o si lo preferís </h3>
        
            <div className="mt-4" >
              <a
                className="tellamamos btn btn-outline"
                href="https://wa.me/+543772223834?text=Hola%20buenos%20días!%20Me%20gustaría%20que%20me%20llamen%20para%20realizar%20una%20consulta.%20"
                target="_blank"
              >
                TE LLAMAMOS NOSOTROS!
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
