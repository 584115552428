import { Ayuda } from "../components/public/Ayuda";
import { Contacto } from "../components/public/Contacto";
import { Formulario } from "../components/public/Formulario";
import { Nosotros } from "../components/public/Nosotros";

export const NosotrosScreen = () => {
  return (
    <>
      <Nosotros />
      <Contacto />
      <Ayuda/>
      <Formulario/>
    </>
  );
};
