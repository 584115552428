import { faClock, faLocationDot, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Map from "./Map";

export const Contacto = () => {
  return (
    <>
      <div className="bg-contacto">
        <div className="container pt-4 pb-4">
          <div className="row justify-content-center">
            <div className="col-12">
              <h5 className="estamosubicados text-start text-shadow"  id="tituloDesplegables">
                CONTACTANOS
              </h5>
              <h2 className="titulo-ubicados text-shadow"  id="tituloDesplegables">Estamos Ubicados</h2>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row pb-5 ">
            <div className="col-md-6 d-flex align-items-center">
              <div className="cardContacto text-start mx-auto bg-transparent shadow-lg p-4 mb-4" id="tituloDesplegables">
                <h5 className="text-center text-decoration-underline" >
                  INFORMACION DE CONTACTO
                </h5>
                <br />

                <p className="fs-5" >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ height: 22, paddingRight: 10 }}
                  />

                  <a
                    href="mailto:gejaga12@hotmail.com"
                    className="text-decoration-none"
                    id="tituloDesplegables"
                  >
                    admin@rymcontable.com.ar
                  </a>
                </p>
                <p className="fs-5" >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    style={{ height: 22, paddingRight: 10 }}
                  />
                  <a
                    href="tel://3794223834"
                    className="text-decoration-none"
                    id="tituloDesplegables"
                  >
                    (+54) 3794-223834
                  </a>
                </p>
                <p className="fs-5" >
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ height: 22, paddingRight: 10 }}
                  />
                  (+54) 3794-223834
                </p>
                <p className="fs-5" >
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ height: 22, paddingRight: 10 }}
                  />
                  H. Irigoyen 220, 6to "D"
                  <p>&nbsp;&nbsp;&nbsp;&nbsp; Corrientes Capital</p>
                </p>
                
                <h5 className="text-center text-decoration-underline" >
                  HORARIOS DE ATENCION
                </h5>
                <br />

                <p className="fs-5" >
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ height: 22, paddingRight: 10 }}
                  />
                  Lunes a Viernes de 8:00 - 17:00
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center" >
              <Map />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
