import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import logo from "../../assets/logomarron.png";

export const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="bg-footer">
      <div className="container">
        <div className="row">
          <footer className="image-footer d-flex flex-wrap justify-content-between align-items-center">
            <img
              src={logo}
              alt="logo-footer"
              className="col-md-6 mb-0"
              style={{ height: "100px", width: "200px", marginLeft: "22%" }}

            />

            <div className="nav col-md-6 p-2 justify-content-start" >
              <ul className="nav-list mr-auto">
                <li className="li-footer">
                  <Link className="nav-link mx-2" to="/" id="colorTextIconFooter" onClick={scrollToTop} >
                    Inicio
                  </Link>
                </li>
                <li className="li-footer">
                  <Link className="nav-link mx-2" to="/nosotros" id="colorTextIconFooter" onClick={scrollToTop} >
                    Nosotras
                  </Link>
                </li>
                <li className="li-footer">
                  <Link className="nav-link mx-2" to="/servicios" id="colorTextIconFooter" onClick={scrollToTop} >
                    Servicios
                  </Link>
                </li>
              </ul>

              <ul className="nav-list nav-list-icons">
                <li className="li-footer li-footer-icons">
                  <Link className="nav-link mx-2" to="/twitter"> {/** FALTA ENLACE **/}
                    <FontAwesomeIcon icon={faTwitter} style={{ height: 22 }} id="colorTextIconFooter" className="iconsFooter" />
                  </Link>
                </li>
                <li className="li-footer li-footer-icons">
                  <Link className="nav-link mx-2" target="_blank" to="https://instagram.com/contadorasrym?igshid=NTc4MTIwNjQ2YQ=="> {/** FALTA ENLACE **/}
                    <FontAwesomeIcon icon={faInstagram} style={{ height: 22 }} id="colorTextIconFooter" className="iconsFooter" />
                  </Link>
                </li>
                <li className="li-footer li-footer-icons">
                  <Link className="nav-link mx-2" to="/linkedin"> {/** FALTA ENLACE **/}
                    <FontAwesomeIcon icon={faLinkedin} style={{ height: 22 }} id="colorTextIconFooter" className="iconsFooter" />
                  </Link>
                </li>
                <li className="li-footer li-footer-icons">
                  <Link className="nav-link mx-2" to="/facebook"> {/** FALTA ENLACE **/}
                    <FontAwesomeIcon icon={faFacebook} style={{ height: 22 }} id="colorTextIconFooter" className="iconsFooter" />
                  </Link>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </div>





  );
};
