import bgPrincipal from "../../assets/background.webp";
import logo from "../../assets/logoblanco.png";

export const Principal = () => {
  return (
    <>
      <div style={{ height: "800px", width: "100vw", overflow: "hidden" }}>
        <img
          src={bgPrincipal}
          alt="Home"
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
        <img src={logo} className="logoPrincipal" alt="Logo" />
      </div>
    </>
  );
};
