import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "../../styles/styles.css";

export const Navbar = () => {
  return (
    <>
      <div className="navbar-container">
        <nav className="d-none d-md-flex">
          <div className="container-fluid">
            <div className="row">
              <div className="topnavbar col-12 col-sm-12 d-flex justify-content-between border-bottom text-white ">
                <div className="mt-3" id="textColorNav">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ height: 18 }}
                    className="me-1"
                  />
                  Teléfono
                  <a
                    href="tel://3794223834"
                    className="text-decoration-none"
                    id="textColorNav"
                  >
                    : 3794-223834
                  </a>
                </div>
                <div className="mt-3" id="textColorNav">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ height: 18 }}
                    className="me-1"
                  />
                  Email
                  <a
                    href="mailto:gejaga12@hotmail.com"
                    id="textColorNav"
                    className="text-decoration-none"
                  >
                    : admin@rymcontable.com.ar
                  </a>
                </div>

                <div className="mt-2 mb-2">
                  <Link
                    to="/contacto"
                    className="btn btn-outline-light"
                    id="contactanosNav"
                  >
                    CONTACTANOS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <nav className="navbar-content navbar navbar-expand-md navbar-light bg-white border-bottom ">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link
                    to="/"
                    id="items-navbar"
                    className="nav-link active mx-4"
                    aria-current="page"
                  >
                    INICIO
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/nosotros"
                    id="items-navbar"
                    className="nav-link mx-4"
                  >
                    NOSOTRAS
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/servicios"
                    id="items-navbar"
                    className="nav-link mx-4"
                  >
                    SERVICIOS
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
